@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.backgroundAnimation {
  background-image: url("./assets/img1.png");
  background-size: cover;
  background-position: center;
  animation: slide 10s infinite;
}

@keyframes slide {
  0% {
    background-image: url("./assets/img1.png");
  }
  33% {
    background-image: url("./assets/img2.png");
  }
  67% {
    background-image: url("./assets/img3.png");
  }
}

.profile {
  display: block;
}
.profile1 {
  display: none;
}

@media (max-width: 400px) {
  .profile {
    display: none;
  }

  .profile1 {
    display: block;
  }

  /* styles for devices with a screen width of 600 pixels or more */
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  margin: 3px 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #004AAD;
  height: 6px !important;
}

::selection {
  color: #004AAD;
  background: black;
}
